import {
  Box,
  Text,
  Column,
  Columns,
  TextLinkButton,
  Stack,
  Heading,
  PageBlock,
  Inline,
  Card,
} from 'braid-design-system';
import isMobile from 'is-mobile';
import { Fragment } from 'react';

import type { accountPartsFragment } from 'src/graphql/generated';
import type { AccountSelectionResults } from 'src/types';
import type { PendingAccountEmailStatuses } from 'src/views/pages/SelectAccountPage/SelectAccountPage';

import AccountActionButton from './components/AccountActionButton/AccountActionButton';
import AccountStatusIndicator from './components/AccountStatusIndicator/AccountStatusIndicator';

interface AccountSegmentProps {
  heading: string;
  subheading: string;
  accounts: AccountSelectionResults['edges'];
  handleSelectAccount?: (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    node: accountPartsFragment,
  ) => void;
  handleActivateAccount?: (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    node: accountPartsFragment,
  ) => void;
  pendingAccountEmailStatuses?: PendingAccountEmailStatuses;
}

const AccountSegment = ({
  heading,
  subheading,
  accounts,
  handleSelectAccount,
  handleActivateAccount,
  pendingAccountEmailStatuses,
}: AccountSegmentProps) => {
  const displayAccountId = (
    seekId: string,
    billingId?: string | null,
  ): string => billingId?.replace(/^0+/, '') ?? seekId;

  return (
    <PageBlock width="medium">
      <Stack space="large">
        <Stack space="small">
          {heading ? <Heading level="3">{heading}</Heading> : null}
          {subheading ? <Text>{subheading}</Text> : null}
        </Stack>
        <Card>
          <Stack space="medium" dividers={!isMobile()}>
            {accounts.map(({ node }) => (
              <Fragment key={node.seekId}>
                <Columns space="small" alignY="top" collapseBelow="desktop">
                  <Column width="1/2">
                    <Inline space="small">
                      <AccountStatusIndicator
                        isActive={Boolean(handleSelectAccount)}
                      />

                      <Text>
                        {handleSelectAccount ? (
                          <TextLinkButton
                            id={`${node.seekId}-selectable-link`}
                            onClick={(e) => handleSelectAccount(e, node)}
                          >
                            {node.name}
                          </TextLinkButton>
                        ) : (
                          node.name
                        )}
                      </Text>
                    </Inline>
                  </Column>
                  <Column>
                    <Box paddingLeft={{ mobile: 'medium', desktop: 'none' }}>
                      <Text>
                        {displayAccountId(node.seekId, node.billingId)}
                      </Text>
                    </Box>
                  </Column>
                  {handleActivateAccount ? (
                    <Column width="content">
                      <Box paddingLeft={{ mobile: 'medium', desktop: 'none' }}>
                        <AccountActionButton
                          accountNode={node}
                          handleActivateAccount={handleActivateAccount}
                          pendingAccountEmailStatus={
                            pendingAccountEmailStatuses &&
                            pendingAccountEmailStatuses[node.seekId]
                          }
                        />
                      </Box>
                    </Column>
                  ) : null}
                </Columns>
                {node.childAccounts?.map((childAccountOption) => (
                  <Box marginLeft="medium" key={childAccountOption?.seekId}>
                    <Columns space="none">
                      <Box marginRight="small">
                        <AccountStatusIndicator
                          isActive={Boolean(handleSelectAccount)}
                        />
                      </Box>
                      <Column width="1/2">
                        {handleSelectAccount && (
                          <Text>
                            <TextLinkButton
                              id={`${childAccountOption.seekId}-selectable-link`}
                              onClick={(e) =>
                                handleSelectAccount(e, childAccountOption)
                              }
                            >
                              {childAccountOption.name}
                            </TextLinkButton>
                          </Text>
                        )}
                      </Column>
                      <Column>
                        <Text>
                          {displayAccountId(
                            childAccountOption.seekId,
                            childAccountOption.billingId,
                          )}
                        </Text>
                      </Column>
                    </Columns>
                  </Box>
                ))}
              </Fragment>
            ))}
          </Stack>
        </Card>
      </Stack>
    </PageBlock>
  );
};

export default AccountSegment;
