import { Card, Stack, Heading } from 'braid-design-system';
import type { ComponentProps } from 'react';

interface Props {
  heading: string;
  children: ComponentProps<typeof Stack>['children'];
}

export const FormSection = ({ heading, children }: Props) => (
  <Stack space="large">
    <Heading level="3">{heading}</Heading>
    <Card>
      <Stack space="small">{children}</Stack>
    </Card>
  </Stack>
);
