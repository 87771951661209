export const IconEmailVerified = (props: any) => (
  <svg
    width={128}
    height={128}
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.128 50.3 57.86 76.81l1.235.915 1.234.916 1.332-1.147a3.575 3.575 0 0 1 2.338-.869c.833 0 1.666.29 2.338.869l1.332 1.147 1.234-.916 1.235-.916 35.669-26.462.198-.147c.02.02.03.049.047.072l.16-.149-37.619-32.417a7.036 7.036 0 0 0-9.186 0L21.788 50.123l.15.14c.017-.023.027-.05.046-.07l.144.107Z"
      fill="#392BA7"
    />
    <path
      d="M21.795 50.193v.59a.72.72 0 0 1 .196-.444l-.196-.146ZM106.013 50.346a.72.72 0 0 1 .205.502v-.655l-.205.153Z"
      fill="#6E56E6"
    />
    <path
      d="M42.942 26.366h42.116a7.04 7.04 0 0 1 7.036 7.037v39.515c0 3.14-2.549 5.69-5.689 5.69h-44.81c-3.14 0-5.69-2.55-5.69-5.69V33.403a7.04 7.04 0 0 1 7.037-7.037Z"
      fill="#F8B1DC"
    />
    <path
      d="M85.12 36.209H60.377v3h24.741v-3ZM85.12 44.93H60.377v3h24.741v-3ZM85.12 53.651H43.342v3h41.776v-3ZM82.578 62.373H47.296v3h35.282v-3ZM55.242 36.177H43.388v12.145h11.854V36.177Z"
      fill="#F470BD"
    />
    <path
      d="m57.86 76.809-35.732-26.51-.144-.106c-.02.02-.03.047-.046.069a.73.73 0 0 0-.15.376v53.472a7.956 7.956 0 0 0 .751 3.136l35.32-30.437ZM106.005 50.2l-.198.147L70.138 76.81l35.312 30.429a7.932 7.932 0 0 0 .761-3.384V50.702a.7.7 0 0 0-.159-.43c-.017-.023-.027-.051-.047-.072ZM67.669 78.64l-1.332-1.147A3.575 3.575 0 0 0 64 76.626c-.833 0-1.666.29-2.338.868l-1.332 1.148-36.022 31.042a7.967 7.967 0 0 0 5.467 2.171h68.437c2.12 0 4.042-.831 5.473-2.177L67.669 78.641Z"
      fill="#6E56E6"
    />
    <path
      d="m60.33 78.64-1.235-.915-1.235-.916-35.32 30.437a8.072 8.072 0 0 0 1.768 2.437l36.021-31.042ZM70.138 76.809l-1.234.916-1.235.916 36.016 31.036a8.022 8.022 0 0 0 1.765-2.439l-35.312-30.43Z"
      fill="#503DC9"
    />
    <path
      d="M104.942 127.884c12.671 0 22.942-10.271 22.942-22.942 0-12.67-10.271-22.942-22.942-22.942C92.272 82 82 92.272 82 104.942c0 12.671 10.272 22.942 22.942 22.942Z"
      fill="#EE399E"
    />
    <path
      d="M101.933 114.656a2.889 2.889 0 0 1-2.043-.846l-6.165-6.166a2.89 2.89 0 0 1 4.087-4.086l4.121 4.122 9.877-9.877a2.888 2.888 0 0 1 4.086 0 2.888 2.888 0 0 1 0 4.086l-11.92 11.921a2.887 2.887 0 0 1-2.043.846Z"
      fill="#fff"
    />
  </svg>
);
