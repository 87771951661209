import { useTranslations } from '@vocab/react';
import { TextField, Dropdown, Checkbox } from 'braid-design-system';
import type { FocusEvent } from 'react';

// import { countryNameToCode } from 'src/utils/siteNameToCountryName';
import { useRegistrationPageContext } from 'src/views/pages/RegistrationPage/RegistrationPageContext';
import translations from 'src/views/shared/forms/RegistrationForm/.vocab';
import useFields from 'src/views/shared/forms/RegistrationForm/BusinessDetailsCard/useBusinessDetailsFields';
// import {
//   BusinessIdentifier,
//   isBusinessNumberCountry,
// } from 'src/views/shared/forms/RegistrationForm/BusinessIdentifier/BusinessIdentifier';
import { FormSection } from 'src/views/shared/forms/RegistrationForm/FormSection/FormSection';
import { PhoneNumber } from 'src/views/shared/forms/RegistrationForm/PhoneNumber/PhoneNumber';
import { useRegistrationForm } from 'src/views/shared/forms/RegistrationForm/RegistrationFormContext';
// import {
//   TaxIdentificationNumber,
//   isTaxIdentityCountry,
// } from 'src/views/shared/forms/RegistrationForm/TaxIdentificationNumber/TaxIdentificationNumber.tsx';

interface Props {
  handleFieldBlur: (event: FocusEvent<HTMLInputElement>) => void;
  handleFieldFocus: (event: FocusEvent<HTMLInputElement>) => void;
  email: string;
}

const BusinessDetailsCard = ({
  handleFieldFocus,
  handleFieldBlur,
  email,
}: Props) => {
  const { values, setFieldValue } = useRegistrationForm();
  const context = useRegistrationPageContext();
  const { businessName, isAgency, phoneNumber } = useFields();
  const { t } = useTranslations(translations);

  if (context.loading) {
    return null;
  }

  const { registrationOptions, countries } = context;
  // const selectedCountryCode = countryNameToCode(values.country);

  return (
    <FormSection heading={t('Business Details')}>
      <TextField
        id="businessName"
        label={t('Business name')}
        description={t('Business name description')}
        data={{ 'tracking-id': 'business-name' }}
        value={businessName.value}
        tone={businessName.errorMessage ? 'critical' : undefined}
        message={businessName.errorMessage}
        onChange={businessName.onChange}
        onFocus={handleFieldFocus}
        onBlur={(e) => {
          handleFieldBlur(e);
          businessName.onBlur();
        }}
        reserveMessageSpace
      />
      <PhoneNumber
        id="phoneNumber"
        label={t('Phone number')}
        description={t('Phone number description')}
        phoneNumber={phoneNumber.value}
        onPhoneNumberChange={(p) => phoneNumber.onChange(p || '')}
        message={phoneNumber.errorMessage}
      />

      <Dropdown
        label={t('Country')}
        id="country"
        value={values.country}
        placeholder={t('Country select message')}
        onChange={(e) => setFieldValue('country', e.currentTarget.value)}
        reserveMessageSpace
      >
        {countries.options.map((country) => (
          <option key={country.countryCode} value={country.name}>
            {country.name}
          </option>
        ))}
      </Dropdown>
      {/* {selectedCountryCode && isTaxIdentityCountry(selectedCountryCode) && (
        <TaxIdentificationNumber
          country={selectedCountryCode}
          onBlur={handleFieldBlur}
        />
      )}

      {selectedCountryCode && isBusinessNumberCountry(selectedCountryCode) && (
        <BusinessIdentifier
          country={selectedCountryCode}
          onBlur={handleFieldBlur}
        />
      )} */}

      {registrationOptions.allowAgencyRegistration && (
        <Checkbox
          label={t('Register as Agency')}
          checked={isAgency.value}
          id="isAgency"
          onChange={isAgency.onChange}
          description={t('Register as Agency description', {
            email,
          })}
        />
      )}
    </FormSection>
  );
};

export default BusinessDetailsCard;
