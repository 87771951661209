import { URL_ROOT } from '@seek/adv-constants';
import { useTrackView } from '@seek/cmsu-analytics';
import { useTranslations } from '@vocab/react';
import { Text, Heading, Strong, Stack, List } from 'braid-design-system';
import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { REGISTER_COMPLETE_PAGE_VIEW } from 'src/constants/pageViewUniqueKeys';
import { EmptyPageLayout } from 'src/views/layouts/EmptyPageLayout/EmptyPageLayout';
import { ContactDetails } from 'src/views/shared/ContactDetails/ContactDetails';

import translations from './.vocab';

const RegistrationCompletePage = () => {
  const location = useLocation();
  const { t } = useTranslations(translations);

  const trackFunction = useTrackView(REGISTER_COMPLETE_PAGE_VIEW);

  useEffect(() => {
    if (trackFunction) {
      trackFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!location.state) {
    return <Navigate to={URL_ROOT} />;
  }

  const { email } = location.state.registeredUserDetails;

  return (
    <EmptyPageLayout heading={t('Registration complete page title')}>
      <Stack space="large">
        <Stack space="medium">
          <Text component="p">
            {t('Sent activation email link message')}{' '}
            <Strong data={{ 'dd-privacy': 'mask', 'hj-masked': true }}>
              {email}
            </Strong>
            .
          </Text>
          <Text component="p">
            {t('Follow email link instruction message')}
          </Text>
        </Stack>

        <Stack space="medium" align="center">
          <Heading level="3">{t('Did not receive the email title')}</Heading>
          <List space="small" type="number">
            <Text align="left">{t('Check email is correct message')}</Text>
            <Text align="left">{t('Check your spam folder')}</Text>
          </List>
        </Stack>

        <ContactDetails />
      </Stack>
    </EmptyPageLayout>
  );
};

export default RegistrationCompletePage;
